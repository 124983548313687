'use client';
import { FC } from 'react';
import { cva } from 'class-variance-authority';
import Avatar from '@/entities/Avatar/Avatar';
import Image from 'next/image';
import {
  INotification,
  INotificationCommentAnswer,
  INotificationContestEnd,
  INotificationContestStart,
  INotificationContestWin,
  INotificationGetAward,
  INotificationPhotoComment,
  INotificationPopular,
  INotificationReport,
  INotificationSubscribe,
  INotificationUser,
} from '@/shared/api/notify/getNotifications/types';
import useGetMe from '@/shared/hooks/useGetMe';
import { LocalizedLink } from '@/shared/i18n/routing';
import { useLocale } from 'next-intl';

interface Props {
  item: INotification;
}

const Notification: FC<Props> = ({ item }) => {
  const locale = useLocale();

  const { isAuth, data: me } = useGetMe({ lang: locale });

  const data = {
    subscribe: {
      src: (item as INotificationSubscribe).avatar?.[0],
      type: 'avatar',
      href: `/profile/${(item as INotificationSubscribe).subscriber_id}`,
    },
    photo_comment: {
      src: (item as INotificationPhotoComment).photo_thumbnail?.[0],
      type: 'image',
      href: `/image/${(item as INotificationPhotoComment).photo_id}#comment_${(item as INotificationPhotoComment).comment_id}`,
    },
    contest_win: {
      src: (item as INotificationContestWin).contest_thumbnail?.[0],
      type: 'image',
      href: `/contest/${(item as INotificationContestWin).contest_id}`,
    },
    contest_end: {
      src: (item as INotificationContestEnd).contest_thumbnail?.[0],
      type: 'image',
      href: `/contest/${(item as INotificationContestEnd).contest_id}`,
    },
    contest_start: {
      src: (item as INotificationContestStart).contest_thumbnail?.[0],
      type: 'image',
      href: `/contest/${(item as INotificationContestStart).contest_id}`,
    },
    get_award: {
      src: (item as INotificationGetAward).award_badge?.url,
      type: 'image',
      href: isAuth ? `/profile/${me!.result!.ID}` : '/profile',
    },
    comment_answer: {
      src: (item as INotificationCommentAnswer).photo_thumbnail?.[0],
      type: 'image',
      href: `/image/${(item as INotificationCommentAnswer).photo_id}#comment_${(item as INotificationCommentAnswer).comment_id}`,
    },
    user: {
      src: (item as INotificationUser).avatar?.[0],
      type: 'avatar',
      href: `/profile/${(item as INotificationUser).notifier_id}`,
    },
    report: {
      src: (item as INotificationReport).photo_thumbnail?.[0],
      type: 'image',
      href: `/image/${(item as INotificationReport).photo_id}`,
    },
    popular: {
      src: (item as INotificationPopular).photo_thumbnail?.[0],
      type: 'image',
      href: `/image/${(item as INotificationPopular).photo_id}`,
    },
  }[item.notify_type] ?? {
    href: '',
    src: null,
    type: null,
  };

  return (
    <LocalizedLink href={data.href} className={cvaRoot()}>
      {data.src && data.type === 'avatar' ? (
        <Avatar size={48} src={data.src} />
      ) : null}
      {data.src && data.type === 'image' ? (
        <Image
          className={cvaImage()}
          src={data.src}
          alt=""
          width={48}
          height={48}
        />
      ) : null}

      <dl className={cvaContent()}>
        <dd className={cvaAction()}>{item.title}</dd>
        <dt className={cvaTime({ isRead: Boolean(!item.is_viewed) })}>
          {item.text}
        </dt>
      </dl>
      {!item.is_viewed && (
        <div className={'pt-1.8 px-1.6'}>
          <div className={cvaUnread()} />
        </div>
      )}
    </LocalizedLink>
  );
};

const cvaRoot = cva(['Notification-cvaRoot', 'flex']);

const cvaImage = cva([
  'Notification-cvaImage',
  'w-4.8 h-4.8 object-cover',
  'rounded-[0.8rem]',
]);

const cvaContent = cva([
  'Notification-cvaContent',
  'py-0.4 px-1.2',
  'flex-grow',
]);

const cvaAction = cva(['Notification-cvaAction', 'font-semibold mb-0.1']);

const cvaUnread = cva([
  'Notification-cvaUnread',
  'rounded-full bg-cPurple700 min-w-1.2 h-1.2',
]);

const cvaTime = cva(['Notification-cvaTime', 'duration-300 text-1.4-600'], {
  variants: {
    isRead: {
      true: 'text-cPurple500',
      false: 'text-cGray500',
    },
  },
});

export default Notification;
